


























import { SearchBuilder } from "@/builder";
import { removeDuplicateObj } from "@/helpers/common";
import { debounce } from "@/helpers/debounce";
import { useCoa } from "@/hooks";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataCoa } from "@/models/interface/settings.interface";
import { settingsServices } from "@/services/settings.service";
import { Component, Vue, Watch } from "vue-property-decorator";

const Props = Vue.extend({
  props: {
    value: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    searchBy: {
      type: String,
    },
  },
});

/**
 * @deprecated
 * new component  {@linkcode SelectAccount}
 */
@Component
export default class CoaSelect extends Props {
  coaOption = {
    data: [] as DataCoa[],
    search: "",
    fetching: true,
    initialData: [] as DataCoa[],
  };

  @Watch("value", { immediate: true })
  onChangeValue(newValue: string): void {
    if (newValue && !this.coaOption.data.find(c => c.id === newValue)) {
      this.findBySecureId(newValue);
    }
  }

  created(): void {
    this.getCoaList(true);
  }

  async getCoaList(firstLoad = false): Promise<void> {
    try {
      this.coaOption.fetching = true;
      const param: RequestQueryParamsModel = {
        limit: 20,
        page: 0,
        search: "",
        sorts: "code:asc",
      };
      const searchQuery: string[] = [];
      if (this.coaOption.search) {
        searchQuery.push(
          `code~*${this.coaOption.search}*_OR_description~*${this.coaOption.search}*`
        );
      }

      if (this.searchBy) {
        searchQuery.push(this.searchBy);
      }

      param.search = searchQuery.join("_AND_");

      const res = await settingsServices.listOfCoa(param, "");
      this.coaOption.data = res.data;
      this.$emit("on-getListCoa", res.data);
      if (firstLoad) {
        this.coaOption.initialData = res.data;
      }
      this.coaOption.fetching = false;
    } catch (error) {
      this.coaOption.fetching = false;
    }
  }

  searchCoa(value: string): void {
    debounce(() => {
      this.coaOption.search = value;
      this.getCoaList();
    });
  }

  handleChange(e: string): void {
    this.$emit("change", e);
  }

  /**
   * Fetch coa list from API until matching with @this value
   */
  async fetchMoreCoa(): Promise<void> {
    try {
      this.coaOption.fetching = true;
      let totalPage = 1;
      const param = {
        limit: 20,
        page: 1,
        sorts: "code:asc",
      };
      while (
        !this.coaOption.data.find(c => c.id === this.value) &&
        param.page <= totalPage
      ) {
        const coaOptions = await settingsServices.listOfCoa(param, "");
        totalPage = Math.ceil(coaOptions.totalElements / 20);
        this.coaOption.data = this.coaOption.data.concat(coaOptions.data);
        param.page++;
      }
      this.$emit("on-getListCoa", this.coaOption.data);
      this.coaOption.fetching = false;
    } catch (error) {
      this.coaOption.fetching = false;
    }
  }

  findBySecureId(id: string): void {
    const { findAll } = useCoa();
    const builder = new SearchBuilder();
    const q = builder.push(["secureId", id]).build();
    const params = new RequestQueryParams(q);
    this.coaOption.fetching = true;
    findAll(params)
      .then(response => {
        const [item] = response.data;
        if (!item) return;
        const options = removeDuplicateObj<DataCoa>(
          [...this.coaOption.data, { ...item }],
          "id"
        );
        this.coaOption.data = options;
      })
      .finally(() => {
        this.coaOption.fetching = false;
      });
  }
}
